import request from "@/utils/request";

//获取图形验证码
export function getCaptcha() {
  return request({
    url: "/api/v1/Captcha/Image",
    method: "get",
  });
}
//修改密码获取邮箱验证码
export function getResetPwdCode(data) {
  return request({
    url: "/api/v1/ResetPwdCode/Get",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
// 密码重置
export function setResetPassworde(data) {
  return request({
    url: "/api/v1/ResetPassword",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//用户登录
export function setLogin(params) {
  return request({
    url: "/api/v1/Login",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    params,
  });
}
//用户注册
export function setSignUp(data) {
  return request({
    url: "/api/v1/SignUp",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//获取财务信息
export function getFinance() {
  return request({
    url: "/api/v1/Finance",
    method: "get",
  });
}
//更新财务设置
export function setFinance(data) {
  return request({
    url: "/api/v1/Finance/Set",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
// 获取全部银行账号
export function getAllBank() {
  return request({
    url: "/api/Bank/GetAll",
    method: "get",
  });
}

//获取全部省份
export function getAllProvince() {
  return request({
    url: "/api/Province/GetAll",
    method: "get",
  });
}
//获取全部城市
export function getAllCity(params) {
  return request({
    url: "/api/City/GetAll",
    method: "get",
    params,
  });
}

// 获取个人基础信息
export function getProfile() {
  return request({
    url: "/api/v1/Profile",
    method: "get",
  });
}

// 更新个人基础信息
export function setProfile(data) {
  return request({
    url: "/api/v1/Profile/Set",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
//项目平台返回数据同步
export function setProjectCallback(data) {
  return request({
    url: "/api/v1/ProjectCallback",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//Quota
export function getQuota(params) {
  return request({
    url: "api/v1/Quota",
    method: "get",
    params
  });
}
//修改密码
export function setChangePassword(data) {
  return request({
    url: "/api/v1/ChangePassword",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//资源中心获取左侧导航
export function getLeftNavs() {
  return request({
    url: "/api/v1/Source/LeftNavs",
    method: "get",
  });
}
// 获取右侧导航内容
export function getSourceRightNavs(params) {
  return request({
    url: "/api/v1/Source/RightNavs",
    method: "get",
    params,
  });
}
// 获取导航内容
export function getSourceContent(params) {
  return request({
    url: "/api/v1/Source/Content",
    method: "get",
    params,
  });
}

// 获取左侧导航和锚点
export function getLeftNavsId(params) {
  return request({
    url: `/api/v1/Source/LeftNavs/${params.leftNavId}`,
    method: "get",
    params,
  });
}
