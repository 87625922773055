//app相关
import request from "@/utils/request";

//获取应用列表
export function getApps(params) {
  return request({
    url: "/api/v1/Apps",
    method: "get",
    params,
  });
}
//创建应用
export function setCreateApp(data) {
  return request({
    url: "/api/v1/CreateApp",
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//编辑应用
export function setEditApp(data) {
  return request({
    url: "/api/v1/EditApp",
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//删除应用
export function setDeleteApp(data) {
  return request({
    url: "/api/v1/DeleteApp",
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}
//获取全部国家
export function getCountryAll() {
  return request({
    url: "/api/Country/GetAll",
    method: "get",
  });
}
//应用分类统计
export function getAppCateStats() {
  return request({
    url: "/api/v1/AppCateStats",
    method: "get",
  });
}

//问卷列表
export function getProjects(params) {
  return request({
    url: "/api/v1/Projects",
    method: "get",
    params,
  });
}
//问卷详情
export function getProjectsDetails(id) {
  return request({
    url: `/api/v1/Projects/${id}`,
    method: "get",
  });
}

//提现列表
export function getOrders(params) {
  return request({
    url: `/api/v1/Orders`,
    method: "get",
    params,
  });
}

//提现
export function setCreateOrder(data) {
  return request({
    url: "/api/v1/CreateOrder",
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

export function getOrdersDetails(id) {
  return request({
    url: `/api/v1/Orders/${id}`,
    method: "get",
  });
}

//账户积分记录
export function getCashLogs(params) {
  return request({
    url: `/api/v1/CashLogs`,
    method: "get",
    params,
  });
}

//生成数据下载地址
export function getParticipations(params) {
  return request({
    url: `/api/v1/Projects/${params.id}/Participations`,
    method: "get",
    params,
  });
}

//生成QC数据下载地址
export function getBadData(params) {
  return request({
    url: `/api/v1/Projects/${params.id}/BadData`,
    method: "get",
    params,
  });
}

// 获取应用详情
export function getAppsDetails(id) {
  return request({
    url: `/api/v1/Apps/${id}`,
    method: "get",
  });
}

// 获取跳转页面设置
export function getRedirects(id) {
  return request({
    url: `/api/v1/App/Redirects/${id}`,
    method: "get",
  });
}

// 设置跳转页面设置
export function setRedirects(data) {
  return request({
    url: `/api/v1/App/Redirect/Set`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//获取项目开/关设置
export function getProjS2S(id) {
  return request({
    url: `/api/v1/App/ProjS2S/${id}`,
    method: "get",
  });
}
//设置项目开/关设置
export function setProjS2S(data) {
  return request({
    url: `/api/v1/App/ProjS2S/Set`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//发票扫描件上传
export function setFapiaoUpload(data) {
  return request({
    url: `/api/v1/Fapiao/Upload`,
    method: "post",
    headers: {
      "content-type": "multipart/form-data",
    },
    data
  });
}

//获取调查墙设置
export function getAppWall(id) {
  return request({
    url: `/api/v1/App/Wall/${id}`,
    method: "get",
  });
}
export function setAppWall(data) {
  return request({
    url: `/api/v1/App/Wall/Set`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//上传logo
export function setLogoUpload(data) {
  return request({
    url: `/api/v1/Logo/Upload`,
    method: "post",
    headers: {
      "content-type": "multipart/form-data",
    },
    data,
  });
}

//获取消息列表
export function getMessages(params) {
  return request({
    url: `/api/v1/Messages`,
    method: "get",
    params,
  });
}

//批量已读消息
export function setReadMessage(data) {
  return request({
    url: `/api/v1/ReadMessage`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//渠道汇总数据
export function getReportSummary() {
  return request({
    url: `/api/v1/Report/Summary`,
    method: "get",
  });
}

//渠道当天数据
export function getReportToday() {
  return request({
    url: `/api/v1/Report/Today`,
    method: "get",
  });
}

//获取全部未读消息数量
export function getMessagesUnreadCount() {
  return request({
    url: `/api/v1/Messages/UnreadCount`,
    method: "get",
  });
}

//获取echars数据报表
export function getReportChart(params) {
  return request({
    url: `/api/v1/Report/Chart`,
    method: "get",
    params,
  });
}

export function getAvailableSurveys(params) {
  return request({
    url: `/api/v1/Apps/${params.app_id}/AvailableSurveys`,
    method: "get",
    params,
  });
}

// 生成奖励数据下载地址
export function getCashLog(params) {
  return request({
    url: `/api/v1/Projects/${params.id}/CashLog`,
    method: "get",
    params,
  });
}

//首页本周与上周数据对比接口
export function getComparison(params) {
  return request({
    url: `/api/v1/Report/Comparison`,
    method: "get",
    params,
  });
}

//获取邮箱验证码
export function setBindMailCode(data) {
  return request({
    url: `/api/v1/BindMailCode/Get`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

// 获取手机验证码
export function setBindMobileCode(data) {
  return request({
    url: `/api/v1/BindMobileCode/Get`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//绑定邮箱
export function setProjectMail(data) {
  return request({
    url: `/api/v1/ProjectMail/Set`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//绑定手机号
export function setProjectPhone(data) {
  return request({
    url: `/api/v1/ProjectPhone/Set`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//项目订阅通知
export function setSubscribe(data) {
  return request({
    url: `/api/v1/Subscribe`,
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    data,
  });
}

//获取更新日志
export function getBlog(params) {
  return request({
    url: `/api/Blog`,
    method: "get",
    params
  });
}
//app数据下载
export function getAppsBadData(params) {
  return request({
    url: `/api/v1/Apps/${params.id}/BadData`,
    method: "get",
    params
  });
}


