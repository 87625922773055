import router from "./router";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // nprogress样式文件
import { getStorage } from "@/utils/auth";
import { notLoggedIn } from "@/router/notLoggedIn/index";
import { Message } from "element-ui";
import store from "./store";
//个性化配置
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

//当路由开始跳转时
router.beforeEach((to, from, next) => {
  //console.log(`brefore redirect to:${JSON.stringify(to)}==> from:${JSON.stringify(from)}`)

  store.commit("user/clearCancelToken")
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  //开启进度条
  NProgress.start();

  //没有token,只能在浏览home页面
  const token = getStorage("token");
  if (!token) {
    const isNext = notLoggedIn.some((item) => {
      return to.fullPath.indexOf(item.path) !== -1;
    });
    if (isNext) {
      next();
    } else {
      next("/login?loginType=3");
      Message({
        showClose: true,
        message: "登录状态已失效请重新登录",
        duration: 3000,
        type: "warning",
      });
    }
  } else {
    next();
  }
});

//当路由跳转结束后
router.afterEach((to, from) => {
  //console.log(`brefore redirect to:${JSON.stringify(to)}==> from:${JSON.stringify(from)}`)
  // 关闭进度条
  NProgress.done();
});
