//添加应用
let user = {
  namespaced: true,
  state: {
    isApplication: false, //是否存在应用
    noMessageNumber: 0, //未读消息数量
    isOK: false, //是否请求完成
  },
  mutations: {
    setIsApplication(state, val) {
      state.isApplication = val;
    },
    setNoMessageNumber(state, val) {
      state.noMessageNumber = val;
    },
    setIsOK(state, val) {
      state.isOK = val;
    },
  },
  getters: {},
  actions: {
    setIsApplication(state, val) {
      return new Promise((resolve) => {
        setTimeout(function () {
          state.isApplication = val;
          resolve(state.isApplication);
        }, 2000);
      });
    },
  },
};

export default user;
