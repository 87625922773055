import Vue from "vue";
import VueRouter from "vue-router";

import { notLoggedIn } from "@/router/notLoggedIn";
import { afterLogin } from "@/router/afterLogin";

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/404",
    hidden: true,
    meta: { title: "404" },
  },
  ...notLoggedIn,
  ...afterLogin,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
