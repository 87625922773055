// 登录后的路由
import Layout from "@/components/Layout/index.vue";
export const afterLogin = [
  {
    path: "/homePage",
    component: Layout,
    redirect: "/homePage/index",
    name: "首页",
    icon: "home-page",
    isChildShow: false, //是否有子元素可以显示
    children: [
      {
        path: "index",
        component: () => import("@/views/homePage/index"),
        meta: { title: "首页" },
      },
    ],
  },
  {
    path: "/application",
    component: Layout,
    redirect: "/application/index",
    name: "我的应用",
    icon: "application",
    isChildShow: false, //是否有子元素可以显示
    children: [
      {
        path: "index",
        component: () => import("@/views/application/index"),
        meta: { title: "我的应用" },
      },
      {
        path: "questionnaireList",
        component: () => import("@/views/application/questionnaireList"),
        meta: { title: "问卷列表" },
      },
      {
        path: "setting",
        component: () => import("@/views/application/setting"),
        meta: { title: "应用设置" },
      },
    ],
  },
  {
    path: "/income",
    component: Layout,
    redirect: "/income/finance",
    name: "我的收益",
    icon: "income",
    isChildShow: true, //是否有子元素可以显示
    children: [
      {
        path: "finance",
        component: () => import("@/views/income/finance"),
        meta: { title: "财务信息", path: "/income/finance" },
      },
      {
        path: "withdrawal",
        component: () => import("@/views/income/withdrawal"),
        meta: { title: "我的提现", path: "/income/withdrawal" },
      },
    ],
  },
  {
    path: "/dataDownload",
    component: Layout,
    redirect: "/dataDownload/index",
    name: "数据下载",
    icon: "data-download",
    isChildShow: false, //是否有子元素可以显示
    children: [
      {
        path: "index",
        component: () => import("@/views/dataDownload/index"),
        meta: { title: "数据下载" },
      },
    ],
  },
  {
    path: "/account",
    component: Layout,
    redirect: "/account/basicInformation",
    name: "账号设置",
    icon: "account",
    isChildShow: true, //是否有子元素可以显示
    children: [
      {
        path: "basicInformation",
        component: () => import("@/views/account/basicInformation"),
        meta: { title: "基本信息", path: "/account/basicInformation" },
      },
      {
        path: "passwordModification",
        component: () => import("@/views/account/passwordModification"),
        meta: { title: "密码修改", path: "/account/passwordModification" },
      },
    ],
  },
  {
    path: "/message",
    component: Layout,
    redirect: "/message/mynews",
    name: "消息管理",
    icon: "message",
    isChildShow: true, //是否有子元素可以显示
    children: [
      {
        path: "mynews",
        component: () => import("@/views/message/mynews"),
        meta: { title: "我的消息", path: "/message/mynews" },
      },
      {
        path: "setting",
        component: () => import("@/views/message/setting"),
        meta: { title: "消息设置", path: "/message/setting" },
      },
    ],
  },
];
