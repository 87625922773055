//未登录时路由
export const notLoggedIn = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
    meta: { title: "404" },
  },
  {
    path: "/home",
    component: () => import("@/views/home"),
    hidden: true,
    meta: { title: "问卷集" },
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
    hidden: true,
    meta: { title: "登录和注册" },
  },
  {
    path: "/resource",
    component: () => import("@/views/resource"),
    hidden: true,
    meta: { title: "资源中心" },
  },
  {
    path: "/preview",
    component: () => import("@/views/preview"),
    hidden: true,
    meta: { title: "问卷列表" },
  },

  {
    path: "/callback",
    component: () => import("@/views/callback"),
    hidden: true,
    meta: { title: "callback" },
  },
  {
    path: "/quota",
    component: () => import("@/views/quota"),
    hidden: true,
    meta: { title: "quota" },
  },
  {
    path: "/endpage",
    component: () => import("@/views/endpage"),
    hidden: true,
    meta: { title: "配额" },
  },
  {
    path: "/forgetPassword",
    component: () => import("@/views/forgetPassword"),
    hidden: true,
    meta: { title: "忘记密码" },
  },
];
