//获取本地缓存
export function getStorage(key) {
  return localStorage.getItem(key);
}
//设置本地缓存
export function setStorage(key, value) {
  return localStorage.setItem(key, value);
}
//移除本地缓存
export function removeStorage(key) {
  return localStorage.removeItem(key);
}
