import Vue from 'vue'
import Vuex from 'vuex'
const path = require('path')
 
Vue.use(Vuex)
 
const files = require.context('./modules', false, /\.js$/)
let modules = {}
files.keys().forEach(key => {
  let name = path.basename(key, '.js')
  modules[name] = files(key).default || files(key)
})
 
let store = new Vuex.Store({
  modules
});
 
export default store