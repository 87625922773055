import axios from "axios";
import { getStorage } from "@/utils/auth";
import { Message } from "element-ui";
import router from "@/router";
import store from "../store";
// 创建axios实例
console.log(1234, process.env.VUE_APP_PROXY_HTTP);
const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  timeout: 500000, // 请求超时
});
// let cancel = null;
//请求拦截
service.interceptors.request.use(
  (config) => {
    // 如果token存在所有请求都携带token
    if (getStorage("token")) {
      config.headers["Authorization"] = "Bearer " + getStorage("token");
    }

    //取消请求
    config.cancelToken = new axios.CancelToken((cancel) => {
      store.commit("user/addCancelToken", cancel);
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//相应拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.status && res.status !== 200) {
      Message({
        showClose: true,
        message: res.msg,
        duration: 3000,
        type: "error",
      });
    }
    return res;
  },
  (error) => {
    if (error.response) {
      const res = error.response.data;
      Message({
        showClose: true,
        message: res.msg,
        duration: 3000,
        type: "error",
      });
      if (res.status === 401) {
        console.log("router", router.push("/login?loginType=3"));
      }
    }
  }
);

export default service;
