import Vue from "vue";
import VueI18n from "vue-i18n";
//导入自己的语言包
import zh from "./language/zh";
import en from "./language/en";

Vue.use(VueI18n);
//准备语言
const messages = {
  //1. 英文
  en: {
    ...en,
    //导入element-ui里面的国际化语法
  },
  //2. 中文
  zh: {
    ...zh,
  },
};

//2. 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  // locale: Cookie.get('language') || 'zh', // 从cookie中获取语言类型 获取不到就是中文
  locale: "zh", //选中的语言
  messages, //语言环境
});

export default i18n;
