let user = {
  namespaced: true,
  state: {
    token: null,
    userInfo: null,
    cancelTokenArr: [],
  },
  mutations: {
    setToken(state, val) {
      state.token = val;
    },
    setUserInfo(state, val) {
      state.userInfo = val;
    },
    addCancelToken(state, cancel) {
      if (!state.cancelTokenArr) {
        state.cancelTokenArr = [];
      }
      if (cancel) {
        state.cancelTokenArr.push(cancel);
      }
    },
    // 取消所有请求
    clearCancelToken(state) {
      state.cancelTokenArr.forEach((c) => {
        if (c) {
          c();
        }
      });
      state.cancelTokenArr = [];
    },
  },
  getters: {},
  actions: {},
};

export default user;
