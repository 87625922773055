import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入elementUI组件
import {
  Dialog,
  Button,
  Progress,
  ColorPicker,
  Upload,
  Switch,
  Input,
  Popover,
  Breadcrumb,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Badge,
  BreadcrumbItem,
  DatePicker,
  Select,
  Option,
  Loading,
  Tooltip,
  Skeleton,
  SkeletonItem,
  Cascader,
  Pagination,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  Message,
  MessageBox,
  Radio,
  RadioGroup,
  RadioButton,
  Alert,
} from "element-ui";

//引入字体库和全局样式
import "./assets/css/globalStyle.css";

// 路由处理
import "./permission";

// 语言切换
import i18n from "./lang/index";

// svg处理
import "@/icons";

//引入复制插件
import VueClipBoard from "vue-clipboard2";

//引入滑动验证
import dragVerify from "vue-drag-verify2";

Vue.use(dragVerify);

Vue.use(VueClipBoard);
Vue.use(Dialog)
  .use(Button)
  .use(Progress)
  .use(ColorPicker)
  .use(Upload)
  .use(Switch)
  .use(Input)
  .use(Popover)
  .use(Breadcrumb)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Badge)
  .use(BreadcrumbItem)
  .use(DatePicker)
  .use(Select)
  .use(Option)
  .use(Loading)
  .use(Loading.directive)
  .use(Tooltip)
  .use(Skeleton)
  .use(SkeletonItem)
  .use(Cascader)
  .use(Pagination)
  .use(Table)
  .use(TableColumn)
  .use(Carousel)
  .use(CarouselItem)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Alert);

Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
