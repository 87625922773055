<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  font-family: Microsoft YaHei;
}
</style>
